import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { GatsbyImage } from 'gatsby-plugin-image'
import arrowleft from '../../images/banners/arrowleft.svg'
import arrowright from '../../images/banners/arrowright.svg'
import './bannerHome.scss'

const BannerHome = () => {
  const data = useStaticQuery(
    graphql`{
  banners: allCloudOfertasBanner(filter: {active: {eq: true}}) {
    nodes {
      order
      startDate
      banner {
        url
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      mobileBanner {
        url
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      title
      tariff {
        id
        name
      }
      url
      status
      showAll
      id
    }
  }
  tarifas: allCloudOfertasTarifa {
    nodes {
      id
      name
    }
  }
}
`
  )

  // /* sort by order of creation date */
  // data.banners.nodes.sort((a, b) => {
  //   const date1 = new Date(a.startDate)
  //   const date2 = new Date(b.startDate)
  //   return date1 - date2
  // })

  const banners = data.banners.nodes
  const tarifas = data.tarifas.nodes

  const [banner, setBanner] = useState([])

  useEffect(() => {
    const temp = JSON.parse(typeof window !== 'undefined' && window.localStorage.getItem('currentMarket'))
    const tarifa = temp && tarifas.filter((tariff) => tariff.id === temp.tariff.id)[0]
    const bannersShowAll = banners.filter(banner => banner.showAll === true)
    if (tarifa) {
      const bannersTariff = banners.filter(banner => banner.tariff && banner.tariff.id === tarifa.id)
      const banner = bannersShowAll.concat(bannersTariff)
      setBanner(banner)
    } else {
      setBanner(bannersShowAll)
    }
  }, [])

  banner.sort((a, b) => {
    return a.order - b.order
  })

  return <>
    <Carousel showArrows={true} showThumbs={false} showIndicators={false} showStatus={false} infiniteLoop={true}
      interval={5000} autoPlay={true}
      renderArrowPrev={(onClickHandler, hasPrev, label) => hasPrev && (<button type="button" onClick={onClickHandler} title={label} className="buttonArrow" style={{ left: '1rem' }}> <img src={arrowleft} alt="Seta para esquerda" /> </button>)}
      renderArrowNext={(onClickHandler, hasPrev, label) => hasPrev && (<button type="button" onClick={onClickHandler} title={label} className="buttonArrow" style={{ right: '1rem' }}> <img src={arrowright} alt="Seta para direita" /> </button>)}>
      {banner.length > 0 &&
        banner.map((banner, index) => {
          // const sources = [
          //   banner.mobileBanner.childImageSharp.fluid,
          //   {
          //     ...banner.banner.childImageSharp.fluid,
          //     media: '(min-width: 768px)'
          //   }
          // ]
          return (
            <div key={`${banner.name}-${index}`}>
              {banner.mobileBanner &&
                <a href={banner.url} key={banner.name} target={banner.target} rel="noreferrer" className="d-md-none d-block">
                  <GatsbyImage
                    image={banner.mobileBanner.childImageSharp.gatsbyImageData}
                    style={{ width: 100 + '%' }}
                    alt="Banner DIA" />
                </a>
              }
              {banner.banner &&
                <a href={banner.url} key={banner.name} target={banner.target} rel="noreferrer" className="d-md-block d-none">
                  <GatsbyImage
                    image={banner.banner.childImageSharp.gatsbyImageData}
                    style={{ width: 100 + '%' }}
                    alt="Banner DIA" />
                </a>
              }
            </div>
          )
        })}
    </Carousel>
  </>
}

export default BannerHome
